export default {
  token: (state) => state.token,

  user: (state) => state.user,

  userIsAdmin: (state) => state.user.admin,

  isAuthenticated: (state) => state.isAuthenticated,

  mimicEnabled: (state) => state.mimicEnabled,

  mimicToken: (state) => state.mimicToken,

  mimicUser: (state) => state.mimicUser,

  mfaEmail: (state) => state.mfaEmail,

  clientId: (state) => state.clientId,

  rememberDays: (state) => state.rememberDays,

  userVenueEditPermissions: (state) => (venueId) => {
    const found = state.userPermissions.find(
      (permission) => permission.resource_type === 'venues' && permission.resource_id === venueId && permission.level === 'admin',
    );
    return !!found;
  },

  userIsSuperAdmin: (state) => {
    const superAdminEmails = [
      'nick@roveiq.com',
      'ndenlinger@roveiq.com',
      'davis@roveiq.com',
      'joel@roveiq.com',
    ];
    if (import.meta.env.DEV) {
      superAdminEmails.push('test@test.com');
      superAdminEmails.push('admin@roveiq.com');
    }
    if (!state.user || !state.user.email) return false;
    return superAdminEmails.includes(state.user?.email);
  },
};
