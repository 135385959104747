const state = {
  campaigns: [],
  schedules: [],
};

const getters = {
  all: (state) => state.campaigns,
  schedules: (state) => state.schedules,
  fallbackCampaigns: (state) => {
    return state.campaigns.filter((campaign) => campaign.type === 'fallback');
  },
};

const mutations = {
  setCampaigns: (state, campaigns) => {
    state.campaigns = campaigns;
  },
  setSchedules: (state, schedules) => {
    state.schedules = schedules;
  },
};

const actions = {
  create: async ({ commit, getters, rootGetters }, newCampaign) => {
    const skyos = rootGetters['app/skyOSService'];
    if (newCampaign.value_type === '') {
      delete newCampaign.value_type;
    }
    const campaign = await skyos.campaigns.create(newCampaign);

    const prevCampaigns = getters.campaigns || [];

    prevCampaigns.push(campaign);

    commit('setCampaigns', prevCampaigns);

    return Promise.resolve(campaign);
  },

  delete: async ({ commit, rootGetters, state }, campaignId) => {
    const skyos = rootGetters['app/skyOSService'];

    await skyos.campaigns.delete(campaignId);

    const campaigns = state.campaigns.filter((campaign) => campaign.id !== campaignId);

    commit('setCampaigns', campaigns);
  },

  edit: async ({ commit, rootGetters, state }, updatedCampaign) => {
    const skyos = rootGetters['app/skyOSService'];

    const updated = await skyos.campaigns.update(updatedCampaign);

    const campaigns = state.campaigns.filter((campaign) => campaign.id !== updatedCampaign.id);

    campaigns.push(updated);

    commit('setCampaigns', campaigns);
  },

  fetch: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const campaigns = await skyos.campaigns.list();

    commit('setCampaigns', campaigns);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
